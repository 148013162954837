<template>
	<div class="w-100 po-line-items">
		<v-layout class="py-2" v-if="false">
			<v-spacer></v-spacer>
			<v-col lg="3" md="4" sm="6" class="d-flex">
				<TextInput
					hide-details
					:disabled="pageLoading"
					:loading="pageLoading"
					v-model="search_key"
					class="mt-0"
					style="padding-left: 10px !important"
					@click:clear="clearSearchLineItem()"
					placeholder="Search Line Item..."
					clearable
				></TextInput>
				<v-btn tile depressed color="blue darken-4 white--text" v-on:click="searchLineItem()">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</v-col>
		</v-layout>
		<table width="100%">
			<thead>
				<tr>
					<th class="pa-2" width="40"></th>
					<th class="pa-2 text-center" width="40">#</th>
					<th class="pa-2" width="300">Product</th>
					<th class="pa-2" width="150">Available QTY</th>
					<th class="pa-2" width="150">Type</th>
					<th class="pa-2" width="120">Adjustment Qty</th>
					<th class="pa-2" width="120">QTY Left</th>
					<!-- <th class="pa-2 text-right" width="120">Rate</th>
					<th class="pa-2 text-right" width="120">Amount</th> -->
					<th class="pa-2 text-right" width="40"></th>
				</tr>
			</thead>
			<!-- <tbody > -->
			<Draggable
				tag="tbody"
				v-model="line_items"
				class="draggable-group"
				handle=".draggable-drag-icon"
				v-clickoutside="outsideClicked"
				v-on:change="updateItemOrder($event)"
			>
				<tr v-for="(row, index) in flaggedLineItems" :key="index + line_items.length" class="">
					<td class="py-1 px-2">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<inline-svg v-bind="attrs" v-on="on" :src="$assetURL('media/custom-svg/dot2.svg')" />
							</template>
							<span>Supplier had removed from this product. You can remove this product</span>
						</v-tooltip>
					</td>
					<td class="py-1 px-2 text-center red--text darken-1">{{ index + 1 }}</td>
					<td class="py-1 px-2 red--text darken-1" :class="row.isEditable ? 'px-2' : 'px-3'">
						{{ row.product_name }}
					</td>
					<td
						style="max-width: 300px"
						class="py-1 px-2 text-truncate red--text darken-1"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<!-- <TextAreaInput
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							v-model="row.description"
							:rows="1"
							class="mt-0"
							v-on:blur="updateLineItem(row, index)"
						>
						</TextAreaInput> -->
						<template class="px-1">{{ row.available_stock }}</template>
					</td>
					<td class="py-1 px-2 red--text darken-1" :class="row.isEditable ? 'px-2' : 'px-3'">
						<template class="px-1">{{ row.type }}</template>
					</td>
					<td class="py-1 px-2 red--text darken-1" :class="row.isEditable ? 'px-2' : 'px-3'">
						<template class="px-1">{{ row.quantity }}</template>
					</td>
					<!-- <td class="py-1 px-2 text-right red--text darken-1" :class="row.isEditable ? 'px-2' : 'px-3'">
						<template class="px-1">{{ formatMoney(row.rate) }}</template>
					</td>
					<td class="py-1 px-2 text-right red--text darken-1" :class="row.isEditable ? 'px-2' : 'px-3'">
						<template class="px-1">{{ formatMoney(row.total) }}</template>
					</td> -->
					<td class="py-1 px-2 text-right">
						<v-icon
							color="red"
							class="cursor-pointer"
							@click="deleteLineItems('flag_line_items', row, index)"
							>mdi-delete-outline</v-icon
						>
					</td>
				</tr>

				<tr v-for="(row, index) in line_items" :key="index" class="">
					<td class="py-1 px-2">
						<v-icon class="draggable-drag-icon cursor-move" color="blue darken-4">mdi-drag</v-icon>
					</td>
					<td class="py-1 px-2 text-center">{{ flaggedLineItems.length + index + 1 }}</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<v-autocomplete
							v-if="row.isEditable"
							outlined
							hide-details
							:items="productList"
							:disabled="pageLoading"
							:loading="pageLoading"
							id="Product"
							placeholder="Product"
							v-model="row.product"
							class="mt-0"
							v-on:change="getProductStock(row, index)"
						>
							<!-- 
						append-outer-icon="mdi-plus"
							@click:append-outer="openCreateProduct()"
						v-on:change="getProductPrice(row, index), updateLineItem(row, index), getDiscount()" -->

							<template v-slot:item="data">
								<template>
									<v-list-item-content dense class="text-truncate">
										<v-list-item-title v-html="data.item.text"></v-list-item-title>
									</v-list-item-content>
									<v-list-item-icon dense class="my-2 pl-2 pr-0" v-if="false">
										<v-chip
											:class="`px-1 white--text ${data.item.product_type == 'inventory' ? 'orange' : 'blue'}`"
											label
											small
										>
											<span class="text-capitalize">
												{{ data.item.product_type == "inventory" ? "product" : data.item.product_type }}
											</span>
										</v-chip>
									</v-list-item-icon>
								</template>
							</template>
						</v-autocomplete>
						<template v-else>{{ getProductName(row.product) }}</template>
					</td>
					<td style="max-width: 300px" class="py-1 px-2 text-truncate">
						<TextInput
							v-if="row.isEditable"
							hide-details
							:disabled="true"
							:loading="pageLoading"
							v-model="row.available_stock"
							class="mt-0"
						></TextInput>
						<template v-else>{{ row.available_stock }}</template>
					</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>

						<AutoCompleteInput
							v-if="row.isEditable"
							hide-details
							:items="typeList"
							:disabled="pageLoading"
							:loading="pageLoading"
							id="Type"
							placeholder="Type"
							v-model="row.type"
							item-text="text"
							item-value="value"
							v-on:change="getProductStock(row, index)"
						>
						</AutoCompleteInput>
						<template v-else>{{ row.type }}</template>
					</td>
					<td
						class="py-1 px-2"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<!-- @blur="updateLineItem(row, index), getDiscount()" -->
						<QuantityInput
							v-if="row.isEditable"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							v-model="row.quantity"
							class="mt-0 px-0 text-right"
							type="number"
							hide-spin-buttons
							v-on:keyup="getProductTotal(), getProductStock(row, index)"
						></QuantityInput>
						<template v-else>{{ row.quantity }}</template>
					</td>
					<td
						v-if="false"
						class="py-1 px-2 text-right"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<div class="d-flex justify-space-between">
							<PriceInput
								v-if="row.isEditable"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model.number="row.rate"
								reverse
								class="mt-0"
								type="number"
								hide-spin-buttons
								v-on:keyup="getProductTotal()"
								v-on:blur="fixedOnBlur(), updateLineItem(row, index)"
								v-on:keydown.tab.prevent="tabClicked($event, index)"
							></PriceInput>
							<template v-else>
								{{ formatMoney(row.rate) }}
							</template>
							<div
								style="min-width: 20px"
								class="ml-2 d-flex justify-start"
								v-if="row.is_price_difference"
							>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											style="font-size: 12px !important"
											v-if="row.is_price_difference"
											small
											color="red"
											dark
											v-bind="attrs"
											v-on="on"
										>
											mdi-circle
										</v-icon>
									</template>
									<span>{{ row.is_price_difference_message }}</span>
								</v-tooltip>
							</div>
						</div>
					</td>
					<td
						v-if="false"
						class="py-1 px-2 text-right"
						@click="editRow($event, index)"
						:class="row.isEditable ? 'px-2' : 'px-3'"
					>
						<template v-if="row.total">{{ formatMoney(row.total) }}</template>
						<template v-else>{{ formatMoney(0) }}</template>
					</td>
					<td class="py-1 px-2 text-right">
						<template>{{ row.quantity_left }}</template>
					</td>
					<td class="py-1 px-2 text-right">
						<v-icon
							color="red"
							:disabled="line_items.length == 1 ? true : false"
							class="cursor-pointer"
							@click="deleteLineItems('line_items', row, index)"
							>mdi-delete-outline</v-icon
						>
					</td>
				</tr>
			</Draggable>
			<!-- </tbody> -->
			<tfoot>
				<tr>
					<td class="pa-2 borer-none" colspan="4">
						<v-btn @click="addLineItems()" class="white--text" depressed color="blue darken-4" tile>
							<v-icon left>mdi-plus</v-icon>
							Add Line item
						</v-btn>
					</td>
					<!-- <td class="pa-2 borer-none text-right" colspan="3"><b>Sub Total</b></td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(sub_total) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td> -->
				</tr>
				<tr v-if="false">
					<td class="pa-2 borer-none text-right" colspan="7">
						<div class="d-flex align-items-center justify-content-end">
							<b>Discount</b>
							<div style="max-width: 80px" class="ml-2">
								<QuantityInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="discount_value"
									type="number"
									class="mt-0"
									v-on:blur="getDiscount()"
									v-on:change="getDiscount()"
								></QuantityInput>
							</div>
							<div style="max-width: 60px" class="">
								<AutoCompleteInput
									hide-details
									:items="discountTypeList"
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="discount_value_type"
									class="mt-0 text-center"
									v-on:change="getDiscount()"
								></AutoCompleteInput>
							</div>
						</div>
					</td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(this.discount_amount) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
				<tr v-if="false">
					<td class="pa-2 borer-none text-right" colspan="7">
						<div class="d-flex align-items-center justify-content-end">
							<v-checkbox
								hdie-details
								v-model="tax_applied"
								class="ma-0 pt-0"
								hide-details
								small
								id="is-tax"
								v-on:change="getTax()"
								dense
								color="blue darken-4"
							></v-checkbox>
							<b>Tax</b>
							<div style="max-width: 60px" class="ml-2">
								<span class="fw-600" style="font-size: 16px"> {{ tax_value }} % </span>
								<AutoCompleteInput
									v-if="false"
									hide-details
									:items="taxList"
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="tax_value"
									class="mt-0 text-center"
								></AutoCompleteInput>
							</div>
						</div>
					</td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(getTax()) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
				<tr v-if="false">
					<td class="pa-2 borer-none text-right" colspan="7">
						<div class="d-flex align-items-center justify-content-end">
							<b>Adjustment</b>
							<div style="max-width: 60px" class="ml-2">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									type="number"
									v-on:blur="getAdjustmentAmount()"
									v-on:change="getAdjustmentAmount()"
									v-model="adjustment"
									class="mt-0"
								></TextInput>
							</div>
						</div>
					</td>
					<td class="pa-2 borer-none text-right" width="180">{{ formatMoney(adjustment_amount) }}</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
				<tr v-if="false">
					<td class="pa-2 borer-none text-right" colspan="7">
						<b style="font-size: 16px">Grand Total</b>
					</td>
					<td class="pa-2 borer-none text-right" width="180">
						<b style="font-size: 16px">{{ formatMoney(getGrandTotal()) }}</b>
					</td>
					<td class="pa-2 borer-none text-right" width="40"></td>
				</tr>
			</tfoot>
		</table>
		<Dialog :dialog="importDialog" v-on:close="importDialog = false" v-if="importDialog">
			<template v-slot:title> Upload Excel File </template>
			<template v-slot:body>
				<v-row>
					<v-col md="4" class="py-0">
						<label for="upload-file" class="btx-label mt-3">Select File</label>
					</v-col>
					<v-col md="8" class="py-0">
						<FileUpload
							:disabled="pageLoading"
							:loading="pageLoading"
							id="upload-file"
							placeholder="File"
							:allow-add-more="false"
							v-model="upload_excel.file"
						></FileUpload>
						<p class="mt-2 red--text" v-if="fileError">
							{{ fileError }}
						</p>

					
					</v-col>
					<v-col md="4" class="py-0 mt-3">
						<label for="upload-type" class="btx-label mt-4">Download Sample For Line Items</label>
					</v-col>
					<v-col md="8" class="py-0 mt-3 d-flex">

						<a
							class="blue--text text--darken-4 fw-500 mt-4"
							style="text-decoration: underline"
							@click="getPurchaseOrderItemsSample"
							target="_blank"
							download
							>Download Sample</a
						>
					</v-col>
					<v-col md="12">
						<div v-if="backEndError" class="mt-2 red--text">
							<div class="mt-1" v-for="(error, index) in backEndError" :key="index">
								{{ error }}
							</div>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					depressed
					tile
					@click="(importDialog = false), (backEndError = [])"
				>
					Close</v-btn
				>
				<v-btn
					class="white--text"
					:disabled="pageLoading"
					:loading="pageLoading"
					depressed
					color="blue darken-4"
					tile
					@click="uploadLineItems()"
				>
					Upload File</v-btn
				>
			</template>
		</Dialog>
		<Dialog
			:dialog="deleteConfirmationDialog"
			v-on:close="deleteConfirmationDialog = false"
			v-if="deleteConfirmationDialog"
			:dialog-width="500"
		>
			<template v-slot:title> Are you sure? </template>
			<template v-slot:body>
				<v-row>
					<v-col md="12" class="py-0">
						<p class="mt-0" style="line-height: 18px">
							Are you sure want to delete line item?
							<br />
							Line item can not be recovered once deleted?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="deleteConfirmationLoading"
					depressed
					tile
					@click="deleteConfirmationDialog = false"
				>
					NO</v-btn
				>
				<v-btn
					:disabled="deleteConfirmationLoading"
					:loading="deleteConfirmationLoading"
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					@click="yesDeleteLineItem()"
				>
					YES
				</v-btn>
			</template>
		</Dialog>
		<ProductCreateDialog
			v-if="createProductDialog"
			:dialog="createProductDialog"
			:supplierId="supplier"
			v-on:close="createProductDialog = false"
			v-on:success="refreshProducts()"
		></ProductCreateDialog>
	</div>
</template>
<script>
import { UpdateOrCreateLineItems, DeleteLineItem } from "@/core/lib/quotation.lib";
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
// import SelectUnit from "@/view/components/SelectUnit";
import PriceInput from "@/view/components/PriceInput";
import ProductCreateDialog from "@/view/components/ProductCreateDialog";
import Dialog from "@/view/components/Dialog";
import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import Draggable from "vuedraggable";
/* import { GetAllProduct } from "@/core/lib/common.lib"; */
// import { DeletePOLineItem } from "@/core/lib/purchase-order.lib";
import { cloneDeep, filter, find, map, toNumber } from "lodash";
import { EventBus } from "@/core/event-bus/event.bus";
import { saveData, deleteData } from "@/core/services/local.service";
import "@/core/event-bus/click-outside";
import { SET_LINEITEMS } from "@/core/services/store/lineitem.module";
import FileUpload from "@/view/components/FileUpload";
import { SET_ERROR } from "@/core/services/store/common.module";
import { ImportLineItems, ShowSupplierProductChange } from "@/core/lib/purchase-order.lib";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
//import TextAreaInput from "@/view/components/TextAreaInput";

export default {
	name: "po-line-items",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		supplier: {
			type: Number,
			default: 0,
		},
		show_only_supplier_products: {
			type: Number,
		},
		flaggedLineItems: {
			type: Array,
			default() {
				return [];
			},
		},
		id: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		value: {
			handler(param) {
				this.line_items = param;
			},
			deep: true,
			immediate: true,
		},
	},
	data() {
		return {
			typeList: [
				{ value: "positive", text: "Positive" },
				{ value: "negative", text: "Negative" },
			],
			tax_applied: true,
			pageLoading: false,
			deleteConfirmationDialog: false,
			deleteConfirmationLoading: false,
			createProductDialog: false,
			deleteId: null,
			deleteIndex: -1,
			search_key: null,
			isOutside: true,
			backEndError: [],
			is_sheet_uploaded: 0,
			fileError: null,
			line_items: [
				{
					isEditable: false,
					product: null,
					description: null,
					quantity: 1,
					order: 1,
					uom: null,
					rate: 0,
					total: 0,
					available_stock: 0,
					type: "negative",
					quantity_left: 0,
				},
			],
			importDialog: false,
			upload_excel: {
				type: null,
				file: null,
			},
			taxList: [9],
			tax_value: 9,
			taxable_amount: 0,
			tax_amount: 0,
			discount_value: 0,
			discount_amount: 0,
			discount_value_type: 1,
			discount_type: 1,
			adjustment: 0,
			adjustment_amount: 0,
			sub_total: 0,
			total: 0,
			discountTypeList: [
				{ text: "$", value: 1 },
				{ text: "%", value: 2 },
			],
			productList: [],
			allProductList: [],
		};
	},
	methods: {
		outsideClicked() {
			if (!this.isOutside) {
				const _line_items = this.line_items.map((row) => {
					return { ...row, isEditable: false };
				});
				this.line_items = _line_items;
				this.isOutside = true;
			}
		},

		async searchLineItem() {
			const payload = {
				purchase_order: this.id,
				search: this.search_key,
				frontend_uuid: this.exel_file_uuid,
				per_page: 100,
			};
			// const data = await SearchLineItem(payload);
			// console.log("data", data);
			this.$emit("searchLinItems", payload);
			// this.line_items = data;
		},
		async clearSearchLineItem() {
			const payload = {
				quotation: this.id,
				search: null,
				frontend_uuid: this.exel_file_uuid,
				/* parent: this.id ? this.id : null, */
				per_page: 100,
			};
			this.$emit("searchLinItems", payload);
		},
		changeClicked() {
			this.isOutside = true;
			//console.log("changeClicked");
		},
		updateItemOrder() {
			// const old_index = param.move.oldIndex;
			// const new_index = param.move.newIndex;
			// console.log(param);
			// console.log(this.line_items);
		},
		async updateLineItem(row, index) {
			try {
				const payload = {
					frontend_uuid: this.exel_file_uuid,
					id: row.id ? row.id : null,
					product: row.product ? row.product : 0,
					// product_barcode: row.product_barcode ? row.product_barcode : null,
					uom: row.uom ? row.uom : null,
					description: row.description ? row.description : null,
					quantity: row.quantity ? row.quantity : 0,
					rate: row.rate ? row.rate : 0,
					sub_total: row.total ? row.total : 0,
					total: row.total ? row.total : 0,
					product_type: "inventory",
					order: row.order ? row.order : null,
					parent: this.parent_id ? this.parent_id : 0,
					type: "negative",
					is_sheet_uploaded: this.is_sheet_uploaded ? this.is_sheet_uploaded : 0,
				};
				if (row.product) {
					let url = "";
					url = "line-item/update/temp";
     				const { item, sub_total } = await UpdateOrCreateLineItems(url, payload);
					if (!row.id) {
						this.line_items[index] = item;
					}
					this.sub_total = toNumber(sub_total);
				}
			} catch (error) {
				console.log(error);
			} finally {
				//console.log('first');
			}
		},
		importExcelFile() {
			this.importDialog = true;
		},
		editRow(event, index) {
			this.isOutside = false;
			const element = event.target;
			//console.log(element.nodeName);
			const _line_items = this.line_items.map((row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index].isEditable = true;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
			// if (this.line_items.length == index + 1) {
			// 	this.addLineItems();
			// }
		},
		getDiscount() {
			if (this.discount_value != 0) {
				if (!this.validateNumber(this.discount_value)) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
			}
			const subtotal = toNumber(this.sub_total);
			if (this.discount_value_type == 1) {
				//$
				if (toNumber(this.discount_value) > toNumber(subtotal)) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
				this.discount_amount = toNumber(this.discount_value);
			} else if (this.discount_value_type == 2) {
				//%
				if (toNumber(this.discount_value) > 100) {
					this.discount_value = 0;
					this.discount_amount = 0;
					return false;
				}
				this.discount_amount = toNumber((subtotal * this.discount_value) / 100);
			}
			return this.discount_amount;
		},
		tabClicked(event, index) {
			/* console.log({ index: index, event: event }); */
			if (this.line_items.length == index + 1) {
				this.addLineItems();
			}

			const _line_items = this.line_items.map((row) => {
				return { ...row, isEditable: false };
			});
			this.line_items = _line_items;
			this.line_items[index + 1].isEditable = true;
			this.$nextTick(() => {
				// const inputarea = element.querySelector("input");
				// if (inputarea) {
				// 	inputarea.focus();
				// 	inputarea.select();
				// }
			});
		},
		async deleteLineItems(type, row, index) {
			const payload = {
				index: index,
				line_item: row.id,
				type: "positive",
				app_frontend_uuid: this.exel_file_uuid,
				frontend_uuid: row.frontend_uuid ? row.frontend_uuid : null,
				parent: this.id ? this.id : 0,
			};
			try {
				if (row.id) {
					this.deleteConfirmationLoading = true;
					const { sub_total, line_item_count } = await DeleteLineItem(payload);
					// if (!this.showPrice) {
					this.sub_total = toNumber(sub_total);
					this.line_item_count = line_item_count;
					// }
				}
				if (type == "line_items") {
					this.line_items.splice(index, 1);
				} else if (type == "flag_line_items") {
					this.flaggedLineItems.splice(index, 1);
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.deleteConfirmationLoading = false;
			}
			// }
		},
		async yesDeleteLineItem() {
			try {
				this.deleteConfirmationLoading = true;
				const { sub_total } = await DeleteLineItem(this.deletePayload);
				this.sub_total = toNumber(sub_total);
				this.line_items.splice(this.deletePayload.index, 1);
				// if (!this.line_items.length) {
				// 	this.isLineItems = false;
				// }
			} catch (error) {
				console.log(error);
			} finally {
				this.deleteConfirmationLoading = false;
				this.deleteConfirmationDialog = false;
			}
		},
		addLineItems() {
			const _order = this.line_items.length;
			this.line_items.push({
				isEditable: false,
				id: null,
				uuid: null,
				product: null,
				description: null,
				uom: null,
				quantity: 1,
				rate: 0,
				total: 0,
				order: _order + 1,
				available_stock: 0,
				type: "negative",
				quantity_left: 0,
			});
			this.$emit("input", this.line_items);
		},
		updateCalculation() {
			this.$emit("update:total", {
				tax_value: this.tax_value,
				taxable_amount: this.taxable_amount,
				tax_amount: this.tax_amount,
				discount_value: this.discount_value,
				discount_value_type: this.discount_value_type,
				tax_applied: this.tax_applied ? 1 : 0,
				discount_amount: this.discount_amount,
				discount_type: this.discount_type,
				adjustment: this.adjustment,
				adjustment_amount: this.adjustment_amount,
				sub_total: this.sub_total,
				total: this.total,
			});
			this.$emit("input", this.line_items);
			this.saveToLocalLineItem();
		},
		getProductName(id) {
			const product = find(this.productList, (row) => row.value == id);
			if (product) {
				return product.text;
			} else {
				return null;
			}
		},
		validateNumber(value) {
			const pattern = /^(\d+(?:,\d{1,2})?).*/;
			return pattern.test(value);
		},
		getTax() {
			if (this.tax_applied) {
				const _taxable_amount = Number(this.sub_total) - Number(this.discount_amount);
				this.tax_amount = Number((_taxable_amount * this.tax_value) / 100);
				this.taxable_amount = _taxable_amount;
			} else {
				this.tax_amount = 0;
			}
			return this.tax_amount;
		},
		getAdjustmentAmount() {
			this.adjustment_amount = Number(this.adjustment);
		},
		getGrandTotal() {
			this.getDiscount();
			this.total =
				Number(this.sub_total) +
				Number(this.tax_amount) +
				Number(this.adjustment) -
				Number(this.discount_amount);
			this.updateCalculation();
			return this.total;
		},
		fixedOnBlur() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					rate: Number(row.rate),
					total: Number(row.total),
				};
			});
			this.line_items = _line_item;
		},
		getProductTotal() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					total: this.to_fixed(row.quantity * row.rate),
				};
			});
			this.line_items = _line_item;
		},
		updateTotal() {
			const _line_item = map(this.line_items, (row) => {
				return {
					...row,
					rate: row.total / row.quantity,
				};
			});
			this.line_items = _line_item;
		},
		getProductPrice(row, index) {
			const id = row.product;
			const _product = find(this.productList, (row) => row.id == id);
			//console.log("_product", _product);
			if (_product && _product.product_type == "inventory") {
				this.line_items[index].rate = _product.seller_price;
				this.line_items[index].total = _product.seller_price * row.quantity;
				this.line_items[index].description = _product.description;
				this.line_items[index].uom = _product.uom ? String(_product.uom) : null;
			}
			if (_product && _product.product_type == "service") {
				this.line_items[index].rate = _product.charges;
				this.line_items[index].total = _product.charges;
				this.line_items[index].description = _product.description;
				this.line_items[index].uom = _product.unit ? _product.unit : null;
			}
			this.changeClicked();
			this.saveToLocalLineItem();
		},
		getProductStock(row, index) {
			const id = row.product;

			//check if product is already added in line item then return false
			const exist_in_line_items = find(this.line_items, (row1,index1) => {
				if(index1!=index && row1.product && row1.product == id){
					return true;
				}
				return false;
			});
			
			if(exist_in_line_items){
				this.$store.commit(SET_ERROR, [{ model: true, message: 'This product already used in line items.' }]);
				this.deleteLineItems('line_items', row, index);
				return false;
			}
			
			const _product = find(this.productList, (row) => row.id == id);
			if (_product && _product.product_type == "inventory") {
				this.line_items[index].available_stock = _product.available_stock;
				let qty = row.quantity?parseInt(row.quantity):0;
				this.line_items[index].quantity = qty;
				if (row.type == "negative") {
					this.line_items[index].quantity_left = _product.available_stock - parseInt(qty);
				} else {
					this.line_items[index].quantity_left = _product.available_stock + parseInt(qty);
				}
			}
			this.$emit("input", this.line_items);
		},
		
		/* This method call from the parent components */
		getRelatedProduct(id) {
			if (id) {
				const _product_list = filter(this.all_products, (row) => {
					const exist = find(row["suppliers"], { id: id });
					return exist ? true : false;
				});

				this.productList = map(_product_list, (row) => {
					const exist = find(row["suppliers"], (row) => {
						return row.id == id;
					});
					if (exist) {
						return { ...row, seller_id: exist.id, seller_price: exist.price };
					}
				});

				this.line_items = filter(this.line_items, (row) => {
					const exist = find(this.productList, { id: row.product });
					return exist ? true : false;
				});
			}else{
				this.productList = this.all_products;
			} 
			if (this.$route.name != "purchase-order-update") {
				this.addLineItems();
			}
			deleteData("adjustment_line_items"); //po_line_items
		},
		async showSupplierProductChange() {
			if (this.show_only_supplier_products == 1) {
				this.pageLoading = true;
				try {
					const { items, sub_total } = await ShowSupplierProductChange(
						this.supplier,
						this.exel_file_uuid
					);
					this.line_items = items;
					this.sub_total = toNumber(sub_total);
					if (!items.length) {
						this.line_items = [
							{
								isEditable: false,
								id: null,
								uuid: null,
								product: null,
								description: null,
								uom: null,
								quantity: 1,
								rate: 0,
								total: 0,
								order: 1,
								available_stock: 0,
								type: "negative",
								quantity_left: 0,
							},
						];
					}
				} catch (error) {
					console.log(error);
				} finally {
					this.pageLoading = false;
				}
			}
		},
		updateUIID() {
			this.exel_file_uuid = this.genrate_uuid();
		},
		openCreateProduct() {
			this.createProductDialog = true;
		},
		async uploadLineItems() {
			this.backEndError = [];
			const _this = this;
			if (!_this.upload_excel.file[0].file) {
				this.fileError = "File is required";
				return false;
			} else {
				this.fileError = null;
			}
			_this.uploading = true;
			this.updateUIID();
			const formData = new FormData();
			formData.append(`excel_file`, _this.upload_excel.file[0].file);
			formData.append(`frontend_uuid`, _this.exel_file_uuid);
			formData.append(`show_only_supplier_products`, this.show_only_supplier_products);
			formData.append(`supplier`, this.supplier);
			try {
				const { items, sub_total } = await ImportLineItems(formData);
				this.line_items = items;
				_this.sub_total = toNumber(sub_total);
				// _this.line_item_count = line_item_count;
				_this.is_sheet_uploaded = 1;
				// if (_this.line_items.length) {
				// 	_this.isLineItems = true;
				// }
				this.$store.dispatch(SET_LINEITEMS).then(() => {
					this.refreshProducts();
				});
				//this.getAllProduct();
				_this.importDialog = false;
			} catch (error) {
				if (error?.data?.message?.excel_errros && error?.data?.message?.excel_errros.length) {
					this.backEndError = error?.data?.message?.excel_errros;
				} else if (error?.data?.excel_file) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error?.data?.excel_file[0] }]);
				} else {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				_this.uploading = false;
			}
		},
		getPurchaseOrderItemsSample() {
			if (!this.supplier) {
				this.$store.commit(SET_ERROR, [{ model: true, message: `Please Select Supplier` }]);
				return;
			}
			const _this = this;
			try {
				_this.pageLoading = true;
				let endpoint = `export/line-items/purchase-order?supplier=${this.supplier}&show_only_supplier_products=${this.show_only_supplier_products}`;
				ApiService.setHeader();
				let downloadURL = process.env.VUE_APP_API_URL + endpoint;
				downloadURL = new URL(downloadURL);
				const token = JwtService.getToken();
				downloadURL.searchParams.append("token", token);
				window.open(downloadURL, "_blank");
			} catch (error) {
				console.log(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		saveToLocalLineItem() {
			const items = map(this.line_items, (row) => {
				return {
					id: row.id ? row.id : null,
					isEditable: row.isEditable ? row.isEditable : false,
					uuid: row.uuid ? row.uuid : null,
					product: row.product ? row.product : null,
					description: row.description ? row.description : null,
					quantity: row.quantity ? Number(row.quantity) : 0,
					rate: row.rate ? Number(row.rate) : 0,
					uom: row.uom ? row.uom : null,
					tax_value: row.tax_value ? row.tax_value : 0,
					tax_amount: row.tax_amount ? row.tax_amount : 0,
					sub_total: row.total ? Number(row.total) : 0,
					total: row.total ? Number(row.total) : 0,
					order: Number(row.order),
					product_type: row.product_type ? row.product_type : "inventory",
					available_stock: row.available_stock ? row.available_stock : 0,
					type: row.type ? row.type : null,
					quantity_left: row.quantity_left ? row.quantity_left : 0,
				};
			}).filter((row) => row.product != null && !row.id);
			saveData("adjustment_line_items", items);
		},
		refreshProducts() {
			this.getRelatedProduct(this.supplier);
		},
	},
	computed: {
		...mapGetters(["localDB", "all_products"]),
		getSubTotal: () => {
			return function () {
				let sum = 0;
				this.line_items.forEach((element) => {
					sum = sum + Number(element.total);
				});
				this.sub_total = Number(sum);
				this.getDiscount();
				return this.sub_total;
			};
		},
	},
	components: {
		Draggable,
		TextInput,
		AutoCompleteInput,
		// SelectUnit,
		FileUpload,
		Dialog,
		//TextAreaInput,
		QuantityInput,
		PriceInput,
		ProductCreateDialog,
	},
	beforeDestroy() {
		EventBus.$off("reload:calculation");
	},
	mounted() {
		
		const result = cloneDeep(this.value);
		console.log(result);
		this.exel_file_uuid = this.exel_file_uuid ? this.exel_file_uuid : this.genrate_uuid();
		// this.line_items = result;
		this.line_items =  [
				{
					isEditable: false,
					id: null,
					uuid: null,
					product: null,
					description: null,
					uom: null,
					quantity: 1,
					rate: 0,
					total: 0,
					order: 1,
					available_stock: 0,
					type: "negative",
					quantity_left: 0,
				},
			];
		this.$emit("input", this.line_items);
		
		this.$store.dispatch(SET_LINEITEMS).then(() => {
					this.refreshProducts();
				});
		const _tax_val = this.localDB("tax_value", 0);
		this.tax_value = _tax_val;
		this.productList = this.all_products; 
		EventBus.$on("reload:calculation", (params) => {
			this.tax_value = params.tax_value;
			this.taxable_amount = params.taxable_amount;
			this.tax_amount = params.tax_amount;
			this.discount_value = params.discount_value;
			this.discount_value_type = params.discount_value_type;
			this.discount_amount = params.discount_amount;
			this.discount_type = params.discount_type;
			this.adjustment = params.adjustment;
			this.tax_applied = params.tax_applied ? true : false;
			this.adjustment_amount = params.adjustment;
			this.sub_total = params.sub_total;
			this.total = params.total;
		});
	},
};
</script>
<style scoped>
.po-line-items th {
	font-weight: 500;
}
.po-line-items th,
.po-line-items td:not(.borer-none) {
	border-bottom: 1px solid #c7c7c7;
	border-left: 1px solid #c7c7c7;
	height: 40px;
}
.po-line-items th:first-child,
.po-line-items td:first-child {
	border-left: none !important;
}
</style>
