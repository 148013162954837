import ApiService from "@/core/services/api.service";
import { getToken } from "@/core/services/jwt.service.js";

export const getPurchaseOrderSupplier = ({
	product_uuid,
	supplier_id,
	per_page,
	page,
	purchaser_order,
}) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(
			`inventory/${product_uuid}/supplier/${supplier_id}/pricing-history?per-page=${per_page}&page=${page}&purchase_order=${purchaser_order}`
		)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetPurchaseOrder = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`adjustment/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetCustomer = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`purchase-order/get-options`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetLineItem = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`line-item-options-adjustment`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const DeletePOLineItem = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`line-item/${id}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetGrnNumber = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`grn`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetGRNDetail = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`detail/${uuid}/grn`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetGrnPDF = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`generate-pdf/purchase-order/grn/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const DownloadPDF = ({ url, uuid }) => {
	return new Promise((resolve, reject) => {
		const token = getToken();
		const api_url = process.env.VUE_APP_API_URL;
		const pdf_url = `${api_url}${url}${uuid}?token=${token}`;
		const pdf = window.open(pdf_url, "_blank");
		if (pdf) {
			resolve(true);
		} else {
			reject(false);
		}
	});
};
export const CreateRecievedPOItem = (url, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(url, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const UpdateChangePrice = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`price-update/purchase-order/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteService = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`purchase-order/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreatePurchaseOrder = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("adjustment", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdatePurchaseOrder = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`adjustment/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const ImportLineItems = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.upload(`line-item/import/purchase-order`, params)
			.then(({ data }) => {
				console.log(data);
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ShowSupplierProductChange = (supplier, frontend_uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`line-item/filter/items?supplier=${supplier}&frontend_uuid=${frontend_uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

// Main to Temp;
// http://127.0.0.1:8000/api/v1/purchase-order/0ae3ee46-16a0-4e70-95b7-e515799456db/main-to-tempitems
// {
//     "frontend_uuid": "a7a0ed70-175a-11ee-88a9-8b90bf8290f4"
// }
export const LineItemsMainToTemp = (uuid, frontend_uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`purchase-order/${uuid}/main-to-tempitems`, { frontend_uuid: frontend_uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
