var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{staticClass:"pt-0",class:{
		..._vm.contentClass,
		'mt-3': !_vm.hideTopMargin,
		'dense-autocomplete': _vm.dense,
		'time-picker-autocomplete': _vm.timePicker,
	},attrs:{"id":_vm.id,"label":_vm.label,"loading":_vm.loading,"disabled":_vm.disabled,"clearable":_vm.clearable,"items":_vm.items,"rules":_vm.rules,"prepend-inner-icon":_vm.prependInnerIcon,"append-icon":_vm.appendIcon,"append-outer-icon":_vm.appendOuterIcon,"item-text":_vm.itemText,"item-value":_vm.itemValue,"hide-details":_vm.hideDetails,"return-object":_vm.returnObject,"multiple":_vm.multiple,"autofocus":_vm.autofocus,"readonly":_vm.readonly,"reverse":_vm.reverse,"outlined":"","menu-props":{
		closeOnContentClick: _vm.closeOnContentClick,
	},"placeholder":_vm.getPlaceholder()},on:{"click:clear":function($event){return _vm.$emit('click:clear', true)},"click":function($event){return _vm.clickMenu()},"change":function($event){return _vm.$emit('change', _vm.autocompleteinput)},"blur":function($event){return _vm.$emit('blur', _vm.autocompleteinput)},"click:append-outer":function($event){return _vm.$emit('click:append-outer', true)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(`No ${_vm.placeholder} Found.`)}})],1)]},proxy:true},{key:"item",fn:function(data){return [[_c('v-list-item',{class:[
					data.item.value === _vm.autocompleteinput?.value ? 'custom-active-list' : '',
					data.item.disabled ? 'disable-list-item' : '',
				],on:{"click":function($event){return _vm.selectItem(data)}}},[_c('div',{staticClass:"d-flex w-100 py-1"},[_c('v-list-item-content',{class:data.item.sub_budget_id ? 'ml-8' : '',staticStyle:{"padding-bottom":"-8px"},attrs:{"this.closeOnContentClick":"true"}},[_c('v-list-item-title',[(!data.item.sub_budget_id)?_c('span',[_vm._v(_vm._s(data.item.numbering))]):_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v(_vm._s(_vm.intToChar(data.item.subNumbering - 1)))]),_vm._v("."+_vm._s(data.item.text))])],1),_c('v-list-item-action',[_c('div',{staticStyle:{"width":"80px","display":"flex,justify-content: start"}},[_c('v-chip',{attrs:{"label":"","color":"blue white--text"}},[_vm._v("$"+_vm._s(data.item.cost ? data.item.cost : 0))])],1)])],1)])]]}}]),model:{value:(_vm.autocompleteinput),callback:function ($$v) {_vm.autocompleteinput=$$v},expression:"autocompleteinput"}})
}
var staticRenderFns = []

export { render, staticRenderFns }